import {defaultEnvironment, Environment} from './default-environment';

export const environment: Environment = {
  ...defaultEnvironment,
  name: 'uat',
  url: {
    aws: `https://wmx9cbswtb.execute-api.eu-central-1.amazonaws.com/uat/app`,
    flowTwin: 'https://q.flowsic-applicationdesign.endress.com/'
  },
  initializeSentry: true
};
